import {
  AssessmentSessionListingLayoutProps,
  AssessmentSessionListingLayoutDataFetcher,
  AssessmentSessionListingLayout,
} from "@idsk/assessment-session";
import {
  AssessmentSessionDataFetcher,
  AssessmentSessionLayoutProps,
} from "./components/CodeFrameWorks/AssessmentSession.df";
import {
  ResumeBuilderListingDataFetcher,
  ResumeBuilderListingLayout,
  ResumeBuilderListingProps,
} from "@idsk/resume-builder";
import { attachHandlers } from "@idsk/ui-core-framework";
import { Navigate, Route, Routes } from "react-router-dom";
import { AssessmentLearnRoutes } from "@idsk/assessment-learn";
import { CandidateDashboard } from "@idsk/assessment-dashboard";
import { StudentRouter } from "@idsk/student-web-app";
import { CodeFrameWork, CodeIde } from "./components";

const CodeIdeAssessmentWrapper = attachHandlers<
  AssessmentSessionLayoutProps,
  { type: string; readonly?: boolean }
>("AssessmentSessionListing")(AssessmentSessionDataFetcher)(CodeFrameWork);

const AssessmentSessionList =
  attachHandlers<AssessmentSessionListingLayoutProps>(
    "AssessmentSessionListing"
  )(AssessmentSessionListingLayoutDataFetcher)(AssessmentSessionListingLayout);

const ResumeBuilderList = attachHandlers<ResumeBuilderListingProps>(
  "ResumeBuilderListing"
)(ResumeBuilderListingDataFetcher)(ResumeBuilderListingLayout);

export const RootRouter = () => {
  return (
    <Routes>
      <Route
        index
        path="*"
        element={<Navigate to={`/clients/self/dashboard`} replace />}
      />
      <Route
        path={`/clients/self/dashboard`}
        element={<CandidateDashboard />}
      />
      <Route
        path="/clients/:clientId/assessmentSessions/:id/codeIde/*"
        element={<CodeIdeAssessmentWrapper type="sessionId" />}
      />
      <Route
        path={`/assessment-sessions`}
        element={<AssessmentSessionList />}
      />
      <Route element={<AssessmentSessionList />} />
      <Route path="/resumebuilder" element={<ResumeBuilderList />} />
      <Route path="/learn/*" element={<StudentRouter />} />

      <Route path="/codeIde/*" element={<CodeIde />} />
      {/* Depreciated Learn version 
			<Route
			path="/clients/:clientId/learn/*"
			element={<AssessmentLearnRoutes 
			/>*/}
      <Route
        path="/clients/:clientId/practice/*"
        element={<AssessmentLearnRoutes />}
      />
    </Routes>
  );
};
