// AssessmentSession.df.ts

import { CodeResult } from "@idsk/components-props";
import { message } from "antd";
import {
  AsyncGetAndPatchUI,
  AsyncPutUI,
  useAsyncGetAndPatchUI,
  useAsyncPutUI,
} from "@idsk/components-ui";
import {
  AssessmentSessionDAO,
  useAssessmentSessionPatchHook,
  AssessmentSessionRunCodeRequest,
  AssessmentSessionRunCodeDAO,
} from "./dao/AssessmentSession.dao";
import { useNavigate, useParams } from "react-router";
import {
  AsyncResponse,
  PatchApiHook,
  useMutationWithMetaData,
  useQueryWithMetaData,
} from "@idsk/ui-core-framework";
import { AssessmentSession, Question } from "@idsk/components-props";
import { AxiosError } from "axios";

export interface AssessmentSessionLayoutProps {
  bgSubmitHook: ReturnType<PatchApiHook<AssessmentSession>>;
  asyncAssessmentSessionUi: AsyncGetAndPatchUI<AssessmentSession>;
  onRunCode: AsyncPutUI<
    Omit<AssessmentSessionRunCodeRequest, "id">,
    CodeResult
  >;
  abortRunCode: () => void;
  readonly: boolean;
}

export interface AssessmentSessionAdditionalProps {
  clientId: string;
  assessmentSessionId: string;
}

interface ErrorResponse {
  message: string;
}

const useAssessmentSessionRunCode = () => {
  const runCodeDao = new AssessmentSessionRunCodeDAO();
  const { clientId } = useParams<{ clientId: string }>();
  runCodeDao.setClientId(clientId!);
  const {
    mutationResult: { mutate, reset },
    response,
  } = useMutationWithMetaData<
    Omit<AssessmentSessionRunCodeRequest, "id">,
    CodeResult
  >(runCodeDao);
  return {
    abortRunCode: runCodeDao.abortExecution,
    triggerRequest: mutate,
    response,
    reset,
    type: "POST",
  };
};

const useAssessmentSession: (
  clientId: string,
  id: string,
  type: string,
  readonly: boolean
) => {
  triggerRequest: () => void;
  response: AsyncResponse<AssessmentSession>;
} = (clientId, id, type, readonly) => {
  const {
    queryResult: { refetch, remove },
    response,
  } = useQueryWithMetaData<AssessmentSession>(
    new AssessmentSessionDAO(clientId, id, type, readonly)
  );
  return {
    triggerRequest: refetch,
    response,
    reset: remove,
    type: "GET",
  };
};

export const AssessmentSessionDataFetcher = (props: {
  type: string;
  readonly?: boolean;
}): AssessmentSessionLayoutProps => {
  const navigate = useNavigate();
  const { clientId, id } = useParams<{ clientId: string; id: string }>();
  const patchHook = useAssessmentSessionPatchHook();
  const asyncAssessmentSessionUi = useAsyncGetAndPatchUI(
    {
      ...useAssessmentSession(
        clientId!,
        id!,
        props.type,
        props.readonly ?? false
      ),
      shouldHandleLoading: true,
      onFailure: (error: unknown) => {
        const axiosError = error as AxiosError;

        if (axiosError.isAxiosError && axiosError.response) {
          const errorResponse = axiosError.response.data as ErrorResponse;

          if (
            errorResponse.message.includes(
              `No assessmentSessions assigned for this user with administrationId: ${id}`
            )
          ) {
            message.error("Session is not available at the moment");
            navigate("test-not-started", { replace: true });
          } else if (axiosError.response.status === 400) {
            navigate("accessDenied", { replace: true });
          }
        } else {
          console.error("An unexpected error occurred:", error);
        }
      },
    },
    patchHook
  );

  const runCodeHook = useAssessmentSessionRunCode();
  const asyncAssessmentOnRunUi = useAsyncPutUI(runCodeHook);

  return {
    bgSubmitHook: patchHook,
    asyncAssessmentSessionUi,
    onRunCode: asyncAssessmentOnRunUi,
    abortRunCode: runCodeHook.abortRunCode,
    readonly: props.readonly ? true : false,
  };
};

export const updateQuestionInAssessment = (
  assessment: AssessmentSession,
  questionId: string,
  updatedQuestion: Partial<any>
): AssessmentSession => {
  const updatedSections = assessment.sections.map((section) => {
    const updatedQuestions = section.questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          ...updatedQuestion,
          providedAnswer:
            updatedQuestion.providedAnswer !== undefined
              ? updatedQuestion.providedAnswer
              : question.providedAnswer,
        };
      }
      return question;
    });
    return { ...section, questions: updatedQuestions };
  });

  return { ...assessment, sections: updatedSections };
};

export const patchAssessmentSession = async (
  patchHook: ReturnType<PatchApiHook<AssessmentSession>>,
  assessment: AssessmentSession,
  questionId: string | null,
  updatedQuestion: Partial<Question> | null
) => {
  let updatedAssessment = assessment;
  if (questionId && updatedQuestion) {
    updatedAssessment = updateQuestionInAssessment(
      assessment,
      questionId,
      updatedQuestion
    );
  }

  try {
    await patchHook.triggerRequest(updatedAssessment);
    message.success("Assessment session updated successfully.");
  } catch (error: any) {
    console.error("Error updating assessment session:", error);
    message.error("Failed to update assessment session.");
  }
};
