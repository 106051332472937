import {
  AsyncRequest,
  PutResponse,
  getAxiosInstance,
} from "@idsk/ui-core-framework";
import { QueryObserverOptions } from "@tanstack/query-core";
import { UploadFile } from "antd/lib/upload";
import {
  B2BAssetsAxiosInstance as axios,
  CandidateAxiosInstance,
} from "@idsk/ui-core-framework";

export interface BatchPostResumeRequest {
  clientId: string;
  sessionId: string;
  jobId: string;
  files: UploadFile<any>[];
}

export class BatchPostResumeDao extends AsyncRequest<
  PutResponse,
  BatchPostResumeRequest
> {
  constructor() {
    super();
  }

  getConfig = (): QueryObserverOptions<any, Error> => {
    return {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    };
  };

  private appendUniqueIdToFilename = (filename: string): string => {
    const timestamp = Date.now();
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return `${filename}_${timestamp}`;
    }
    const name = filename.substring(0, lastDotIndex);
    const extension = filename.substring(lastDotIndex);
    return `${name}_${timestamp}${extension}`;
  };

  getSessionInfo = async (sessionId: string) => {
    const axiosInstance = getAxiosInstance();
    try {
      const response = await axiosInstance.get(
        `/v1/d2s/creyas/clients/idsk.id.saju.client/creyaSessions/${sessionId}/sessionInfo`
      );
      return response.data;
    } catch (error: any) {
      console.error("Error fetching session info:", error);
      throw new Error("Failed to fetch session info");
    }
  };

  uploadFiles = async (input: BatchPostResumeRequest): Promise<PutResponse> => {
    const resumeMapping: { [resumeId: string]: string } = {};
    try {
      const uploadPromises = input.files.map(async (file, index) => {
        const epochSeconds = Math.floor(Date.now() / 1000);
        const resumeId = `${epochSeconds}-${index}`;
        const originalFilename = file.name || "file";
        const modifiedFilename =
          this.appendUniqueIdToFilename(originalFilename);
        const url = `/creya/resumes/${modifiedFilename}`;

        await axios({
          url,
          data: file.originFileObj,
          method: "POST",
          headers: {
            "Content-Type": file.type || "application/octet-stream",
          },
        });

        resumeMapping[resumeId] = modifiedFilename;
      });

      await Promise.all(uploadPromises);

      const patchPromises = Object.entries(resumeMapping).map(
        async ([resumeId, filename]) => {
          const patchUrl = `/v1/d2s/creyas/clients/idsk.id.saju.client/creyaSessions/${input.sessionId}`;
          const payload = { resumeId: filename };

          await CandidateAxiosInstance({
            url: patchUrl,
            data: payload,
            method: "PATCH",
          });
        }
      );

      await Promise.all(patchPromises);

      return { status: "SUCCESS" };
    } catch (error: any) {
      console.error("Error during file upload:", error);
      return { status: "FAILURE" };
    }
  };

  getAsyncFunction = async (
    input: BatchPostResumeRequest
  ): Promise<PutResponse> => {
    try {
      const sessionInfoResponse = await this.getSessionInfo(input.sessionId);
      const { sessionInfo, scheduleInfo } = sessionInfoResponse;

      const currentTime = new Date();
      const startTime = new Date(scheduleInfo.StartTime);
      const endTime = new Date(scheduleInfo.EndTime);

      if (currentTime < startTime || currentTime > endTime) {
        console.error("Current time is outside the valid range.");
        return { status: "FAILURE" };
      }

      if (sessionInfo.resumeId) {
        window.location.href = `/candidate/${sessionInfo.id}/instructions`;
        return { status: "SUCCESS" };
      }

      return await this.uploadFiles(input);
    } catch (error: any) {
      console.error("Error in getAsyncFunction:", error);
      return { status: "FAILURE" };
    }
  };

  getCacheKey = (): string[] => {
    return ["BatchPostResume"];
  };
}
