import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message, Spin, Typography, Row, Col } from "antd";
import { IdskButton } from "@idsk/components-ui";
import { Form, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { UserContext } from "@idsk/ui-core-framework";
import { BatchPostResumeDao } from "./BatchPostResume.dao";
import "./CreyaResumeUpload.scss";

const { Dragger } = Upload;

const CareerAdvancement: React.FC = () => {
  const containerStyle = {
    maxWidth: "310px",
    fontSize: "48px",
    color: "#FFF",
    marginLeft: "125px",
    marginTop: "20px",
    lineHeight: "56px",
    fontWeight: 400,
    fontFamily: "'Space Grotesk', serif",
  };

  return (
    <div style={containerStyle}>
      <span style={{ color: "#FFF" }}>Advance {"{"}</span>
      <span style={{ color: "#FDC80D" }}>your career</span>
      <span style={{ color: "#FFF" }}>{"}"}</span>
    </div>
  );
};

export const CreyaResumeUpload = () => {
  const navigate = useNavigate();
  const form = Form.useFormInstance();
  const { sessionId } = useParams<{ sessionId: string }>();
  const { clientId } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [isChecking, setIsChecking] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [redirectCountdown, setRedirectCountdown] = useState<number>(5);

  const batchPostResumeDao = new BatchPostResumeDao();

  useEffect(() => {
    const checkSessionInfo = async () => {
      try {
        if (sessionId) {
          const { sessionInfo, scheduleInfo } =
            await batchPostResumeDao.getSessionInfo(sessionId);

          const currentTime = new Date();
          const startTime = new Date(scheduleInfo.StartTime);
          const endTime = new Date(scheduleInfo.EndTime);

          if (currentTime > endTime) {
            setErrorMessage(
              "Your interview session is already ended. Please wait while we redirect you to the dashboard in 5 seconds."
            );
            startRedirectTimer();
          } else if (currentTime < startTime) {
            setErrorMessage(
              `Your interview session is scheduled on ${startTime.toLocaleString()}. Please come back to take the interview.`
            );
            startRedirectTimer();
          } else if (sessionInfo?.resumeId) {
            navigate(`/candidate/${sessionId}/instructions`);
          } else {
            setIsChecking(false);
          }
        }
      } catch (error) {
        console.error("Error fetching session info:", error);
        message.error("Failed to fetch session info. Please try again.");
      }
    };

    checkSessionInfo();
  }, [navigate]);

  const startRedirectTimer = () => {
    const interval = setInterval(() => {
      setRedirectCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          navigate("/dashboard");
        }
        return prev - 1;
      });
    }, 1000);
  };

  const beforeUpload = async (file: any) => {
    const isPDFOrDoc =
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword";

    if (!isPDFOrDoc) {
      message.error("You can only upload PDF, DOC, or DOCX files!");
      return false;
    }

    setFileList([file]);
    return false;
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error("Please upload a resume before proceeding.");
      return;
    }

    setLoading(true);

    try {
      if (sessionId) {
        const request = {
          clientId,
          sessionId,
          jobId: "job-id-placeholder",
          files: fileList,
        };
        const response = await batchPostResumeDao.uploadFiles(request);
        if (response.status === "SUCCESS") {
          message.success("Resume uploaded successfully!");
          navigate(`/creya/candidate/${sessionId}/instructions`);
        } else {
          throw new Error("Failed to upload resume.");
        }
      }
    } catch (error) {
      console.error("Error uploading resume:", error);
      message.error("Failed to upload the resume. Please try again.");
      navigate("/dashboard");
    } finally {
      setLoading(false);
    }
  };

  if (isChecking) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ height: "100vh", textAlign: "center" }}
      >
        <Spin tip="Checking session info..." size="large" />
      </Row>
    );
  }

  if (errorMessage) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ height: "100vh", textAlign: "center" }}
      >
        <Col>
          <Typography.Title level={3}>{errorMessage}</Typography.Title>
          <Typography.Text>
            Redirecting in {redirectCountdown} seconds...
          </Typography.Text>
        </Col>
      </Row>
    );
  }

  return (
    <Form form={form} layout="vertical" style={{ height: "97vh" }}>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundImage: `url("/stintcodebg.svg")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            height: "auto",
            flexDirection: "column",
          }}
          xs={24}
          md={24}
          lg={13}
        >
          <div
            style={{
              backgroundImage: `url("/CreyaAuth.svg")`,
              height: "90px",
              width: "483px",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          />
          <CareerAdvancement />
          <div
            style={{
              maxWidth: "300px",
              fontSize: "24px",
              color: "#F5F5F5",
              marginLeft: "125px",
              marginTop: "20px",
              lineHeight: "36px",
              fontWeight: "300px",
            }}
          >
            by taking the next step with us!
          </div>
        </Col>
        <Col xs={24} md={24} lg={11} className="idesk-login-container">
          <Row
            align={"middle"}
            justify={"center"}
            gutter={[12, 12]}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "712px",
              backgroundImage: `url("/Login.svg")`,
              backgroundSize: "cover",
              width: "710px",
              backgroundRepeat: "no-repeat",
              height: "auto",
            }}
          >
            <Col span={15}>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "48px",
                  color: "#191919",
                  lineHeight: "56px",
                  marginTop: "120px",
                  textAlign: "center",
                  fontFamily: "'Tenor Sans', sans-serif",
                }}
              >
                Let’s get started!
              </div>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#575757",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontFamily: "'Space Grotesk', serif",
                }}
              >
                Upload and check your skill with Creya✨!
              </div>
            </Col>
            <Col span={14}>
              <Dragger
                accept=".pdf,.doc,.docx"
                beforeUpload={beforeUpload}
                fileList={fileList}
                onRemove={() => setFileList([])}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined style={{ color: "#1890ff", fontSize: 48 }} />
                </p>
                <p className="ant-upload-text">
                  Click or drag your resume to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Supported file types: PDF, DOC, DOCX
                </p>
              </Dragger>
            </Col>
            <Col span={14}>
              <IdskButton
                style={{
                  width: "100%",
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "700",
                  height: "56px",
                  background: "#0D6EFD",
                  border: "2px solid",
                  borderImageSource:
                    "linear-gradient(180deg, #75ADFF 0%, #1D2FA1 100%)",
                  borderImageSlice: 1,
                }}
                role="button"
                onClick={handleUpload}
                size="large"
                loading={loading}
              >
                Upload & Proceed
              </IdskButton>
            </Col>
            <Col span={12} style={{ marginTop: "1%", marginBottom: "24px" }}>
              <Typography.Text>
                Powered by Creya.Ai - IDesk Technologies Pvt Ltd
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
