import { Navigate, Route, Routes } from "react-router";
import { SignInPage, SignUpPage } from "../auth";
import { VerificationOTP } from "../auth/VerificationOTP";
import { Col, Form, Image, Row } from "antd";
import { ForgotPassword } from "../auth/ForgotPassword";
import { ResetPassword } from "../auth/ResetPassword";
import { useEffect, useState } from "react";
import { withTheme } from "styled-components";

export const LoginContainer = withTheme((props: any) => {
  const [form] = Form.useForm();
  const width = window.screen.width;
  const [imageHeight, setImageHeight] = useState<string>(
    window.screen.width < 992 ? "30%" : "100%"
  );
  let defaultLink = width < 992 ? "/StintCodeAuth.png" : "/StintCode.png";
  const [imageLink, setImageLink] = useState<string>(
    props.theme.companyBanner ?? defaultLink
  );

  useEffect(() => {
    const onResize = () => {
      let width = window.screen.width;
      defaultLink = width < 992 ? "/StintCodeAuth.png" : "/StintCode.png";
      setImageHeight(width < 992 ? "30%" : "100%");
      setImageLink(props.theme.companyBanner ?? defaultLink);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <Form form={form} layout="vertical" style={{ height: "97vh" }}>
      <Row style={{ height: "100vh", overflow: "hidden" }}>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: imageHeight,
            backgroundImage: `url("/StintCodeBackground.png")`,
          }}
          xs={24}
          md={24}
          lg={10}
        >
          <img
            src={imageLink}
            style={{
              width:
                window.screen.width < 992 ? `${window.screen.width}px` : "100%",
            }}
          />
        </Col>
        <Col xs={24} md={24} lg={14}>
          <Routes>
            <Route path="reset_password" element={<ResetPassword />} />
            <Route path="forgot_password" element={<ForgotPassword />} />
            <Route path="confirm" element={<VerificationOTP />} />
            <Route path="signup" element={<SignUpPage />} />
            <Route path="login" element={<SignInPage />} />
            <Route path="*" element={<Navigate to="login" />} />
            <Route index element={<Navigate to="login" />} />
          </Routes>
        </Col>
      </Row>
    </Form>
  );
});

// export const LoginContainer = withTheme((props: any) => {
//   const [form] = Form.useForm();
//   // const width = window.screen.width;
//   // const [imageHeight, setImageHeight] = useState<string>(
//   //   window.screen.width < 992 ? "30%" : "100%"
//   // );
//   // let defaultLink = width < 992 ? "/StintCodeAuth.png" : "/StintCode.png";
//   // const [imageLink, setImageLink] = useState<string>(
//   //   props.theme.companyBanner ?? defaultLink
//   // );

//   // useEffect(() => {
//   //   const onResize = () => {
//   //     let width = window.screen.width;
//   //     defaultLink = width < 992 ? "/StintCodeAuth.png" : "/StintCode.png";
//   //     setImageHeight(width < 992 ? "30%" : "100%");
//   //     setImageLink(props.theme.companyBanner ?? defaultLink);
//   //   };
//   //   window.addEventListener("resize", onResize);
//   //   return () => window.removeEventListener("resize", onResize);
//   // }, []);

//   return (
//     <Form form={form} layout="vertical" style={{ height: "97vh" }}>
//       <Row
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           height: "100vh",
//           backgroundImage: `url("/stintcodebg.svg")`,
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//         }}
//       >
//         {/* <div className="idesk-row-container"> */}
//         <Col
//           style={{
//             display: "flex",
//             alignItems: "flex-start",
//             justifyContent: "center",
//             height: "auto",
//             flexDirection: "column",
//           }}
//           xs={24}
//           md={24}
//           lg={13}
//         >
//           <div
//             style={{
//               backgroundImage: `url("/StintCodeAuth.svg")`,
//               height: "90px",
//               width: "483px",
//               backgroundSize: "contain",
//               backgroundRepeat: "no-repeat",
//             }}
//           />
//           <div
//             style={{
//               maxWidth: "300px",
//               fontSize: "48px",
//               color: "#FFF",
//               marginLeft: "125px",
//               marginTop: "20px",
//               lineHeight: "56px",
//               fontWeight: "400px",
//               fontFamily: "'Space Grotesk', serif",
//             }}
//           >
//             Start your journey with us!
//           </div>
//           <div
//             style={{
//               maxWidth: "300px",
//               fontSize: "24px",
//               color: "#F5F5F5",
//               marginLeft: "125px",
//               marginTop: "20px",
//               lineHeight: "36px",
//               fontWeight: "300px",
//             }}
//           >
//             Unlocking StintCode value.
//           </div>
//         </Col>
//         <Col xs={24} md={24} lg={11} className="idesk-login-container">
//           <Routes>
//             <Route path="reset_password" element={<ResetPassword />} />
//             <Route path="forgot_password" element={<ForgotPassword />} />
//             <Route path="confirm" element={<VerificationOTP />} />
//             <Route path="signup" element={<SignUpPage />} />
//             <Route path="login" element={<SignInPage />} />
//             <Route path="*" element={<Navigate to="login" />} />
//             <Route index element={<Navigate to="login" />} />
//           </Routes>
//         </Col>
//         {/* </div> */}
//       </Row>
//     </Form>
//   );
// });
