import { ReactLogo, AngularLogo, VueLogo } from "src/assets";

interface Template {
  name: string;
  logo: JSX.Element;
  templateKey: string;
}

const sandpackTemplates: Template[] = [
  {
    name: "React",
    logo: (
      <ReactLogo
        style={{
          height: 40,
          width: 40,
          margin: "auto",
          objectFit: "contain",
        }}
      />
    ),
    templateKey: "react",
  },
  {
    name: "Vue",
    logo: (
      <VueLogo
        style={{
          height: 40,
          width: 40,
          margin: "auto",
          objectFit: "contain",
        }}
      />
    ),
    templateKey: "vue",
  },
  {
    name: "Angular",
    logo: (
      <AngularLogo
        style={{
          height: 40,
          width: 40,
          margin: "auto",
          objectFit: "contain",
        }}
      />
    ),
    templateKey: "angular",
  },
];

export { sandpackTemplates };
