import { faker } from "@faker-js/faker";
import { Registry, Response } from "miragejs";
import { RouteHandler } from "miragejs/server";
import { ModelRegistry } from "./MirageModels";
import {
  Assessment,
  JobProfileEnum,
  LanguageEnum,
  PartialQuestionFormType,
  bufferEncode,
} from "@idsk/components-props";
import { Buffer } from "buffer";
export const mockGetQuestionById: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const dbData = schema.all("questions").models;
  const application = dbData.find(
    (item) => (item?.attrs as any)?.id === request.params.id
  );
  if (application) {
    return new Response(200, {}, application.attrs);
  } else {
    return new Response(404, {}, { errorMessage: "Not found" });
  }
};

export const mockPatchQuestionById: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let codingQuestion = JSON.parse(request.requestBody);
  const dbData = schema.all("questions").models;
  const application = dbData.find(
    (item) => (item?.attrs as any)?.id === request.params.id
  );
  if (application) {
    application.update(codingQuestion);
    return new Response(200, {}, "SUCCESS");
  } else {
    return new Response(404, {}, { errorMessage: "Not found" });
  }
};

export function createQuestions(): PartialQuestionFormType {
  const difficulty = ["Easy", "Medium", "Hard"];
  const questionType = ["CODING", "MCQ"];
  const clientId = ["idsk.internal.client.id", "self"];
  const answerType = ["MULTI_CHOICE", "SINGLE_CHOICE"];
  let question =
    questionType[Math.floor((Math.random() * 10) % questionType.length)];
  if (question === "CODING") {
    return {
      clientId: clientId[Math.floor((Math.random() * 10) % clientId.length)],
      questionTitle: faker.random.words(10),
      difficulty:
        difficulty[Math.floor((Math.random() * 10) % difficulty.length)],
      topics:
        Object.keys(JobProfileEnum)[
          Math.floor(Math.random() * 100) % Object.values(JobProfileEnum).length
        ],
      id: Math.floor(Math.random() * 1000) + "",
      concept: faker.random.words(5),
      questionType: question,
      category: faker.random.words(5),
      questionTestCases: [
        {
          difficulty:
            difficulty[Math.floor((Math.random() * 10) % difficulty.length)],
          input: "Dinesh",
          output: "dinesh",
          sample: true,
          score: 2,
          testCaseName: faker.random.words(2),
          title: "sample",
        },
        {
          difficulty:
            difficulty[Math.floor((Math.random() * 10) % difficulty.length)],
          input: "Dinesh",
          output: "dinesh",
          sample: true,
          score: 2,
          testCaseName: faker.random.words(2),
          title: "sample",
        },
        {
          difficulty:
            difficulty[Math.floor((Math.random() * 10) % difficulty.length)],
          input: "Dinesh",
          output: "dinesh",
          sample: true,
          score: 0,
          testCaseName: faker.random.words(2),
          title: "sample",
        },
        {
          difficulty:
            difficulty[Math.floor((Math.random() * 10) % difficulty.length)],
          input: "Dinesh",
          output: "dinesh",
          sample: true,
          score: 8,
          testCaseName: faker.random.words(2),
          title: "sample",
        },
      ],
      otherTags: ["java"],
      description: bufferEncode(faker.random.words(50)),
      duration: parseInt(faker.random.numeric(2)),
      codingLanguage: [
        {
          id: 50,
          memoryLimit: 2,
          timeLimit: 1.0,
        },
        {
          id: 63,
          memoryLimit: 2,
          timeLimit: 1.0,
        },
      ],
      returnType: faker.random.words(1),
      functionName: faker.random.words(1),
      configEditable: false,
      parameters: [
        {
          parameterName: "add",
          parameterType: "String",
        },
      ],
      score: 1,
    };
  } else {
    return {
      clientId: clientId[Math.floor((Math.random() * 10) % clientId.length)],
      questionTitle: faker.random.words(10),
      difficulty:
        difficulty[Math.floor((Math.random() * 10) % difficulty.length)],
      topics:
        Object.keys(JobProfileEnum)[
          Math.floor(Math.random() * 100) % Object.values(JobProfileEnum).length
        ],
      concept: faker.random.words(5),

      questionType: question,
      category: faker.random.words(5),
      otherTags: ["java"],
      duration: parseInt(faker.random.numeric(2)),
      parameters: [
        {
          parameterName: "add",
          parameterType: "String",
        },
      ],
      configEditable: false,
      description: bufferEncode(faker.random.words(50)),
      functionName: faker.random.words(1),
      id: Math.floor(Math.random() * 1000) + "",
      returnType: faker.random.words(1),
      answerType:
        answerType[Math.floor((Math.random() * 10) % answerType.length)],
      answerOptions: [
        { id: "1", value: Buffer.from("123").toString("base64") },
        { id: "2", value: Buffer.from("1234").toString("base64") },
        { id: "3", value: Buffer.from("12345").toString("base64") },
        { id: "4", value: Buffer.from("123456").toString("base64") },
      ],
      correctAnswers: ["2"],
      score: 1,
    };
  }
}

export const mockGetQuestions: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  const dbData = schema.all("questions").models;
  const clientId = request.queryParams.clientId ?? "";
  const filteredData = dbData.filter((clientQuestions) => {
    if (clientId === (clientQuestions.attrs as any).clientId) {
      return clientQuestions;
    }
  });
  return {
    total: filteredData.length,
    records: filteredData,
  };
};

export const mockPostQuestion: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema, request) => {
  let codingQuestion = JSON.parse(request.requestBody);
  let response = schema.create("questions", codingQuestion);
  return new Response(200, {}, { id: response.id });
};

export const mockGetMultiQuestions: RouteHandler<
  Registry<typeof ModelRegistry, any>,
  any
> = (schema: any, request: any) => {
  const dbData = schema.all("questions").models;
  return Object.fromEntries(
    (
      JSON.parse(request.requestBody) as {
        queryId: string;
        jobRole: string;
        clientId: string;
        assessmentId?: string;
        difficulty?: string[];
        type?: string[];
        category: string;
      }[]
    ).map(
      ({
        queryId,
        jobRole,
        clientId,
        assessmentId,
        difficulty,
        type,
        category,
      }) => {
        let resp = dbData.filter((el: any) => {
          if (queryId === "recommendedQuestions") {
            return (el.attrs as any).topics == jobRole;
          } else if (queryId === "ideskQuestions") {
            return (el.attrs as any).clientId == clientId;
          } else if (queryId == "myQuestions") {
            return (el.attrs as any).clientId == clientId;
          } else {
            const dbDataAssessments = schema.all("assessments").models;
            let filterAssessment = dbDataAssessments?.find((assess: any) => {
              return (assess.attrs as any).id === assessmentId;
            });
            let addedQuestions = (filterAssessment?.attrs as Assessment)
              .sections[0]?.questions;
            return addedQuestions?.find((question: PartialQuestionFormType) => {
              return el.id === question.id;
            });
          }
        });
        let data = resp as Array<PartialQuestionFormType>;
        if (difficulty) {
          data = data.filter((el) => {
            return difficulty?.includes(el.difficulty ?? "");
          });
        }
        if (type) {
          data = data.filter((el) => {
            return type?.includes(el.questionType ?? "");
          });
        }
        if (category) {
          data = data.filter((el) => {
            return el.category?.startsWith(category);
          });
        }

        let response = {
          records: data,
          total: data.length,
        };
        return [
          queryId,
          {
            ...response,
            records: data.splice(
              parseInt(request?.queryParams?.from),
              parseInt(request?.queryParams?.size)
            ),
          },
        ];
      }
    )
  );
};
