import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Col, Form, message, Row, Typography } from "antd";
import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import {
  UserDetails,
  forgotPasswordSubmit,
  validatePassword,
} from "../cognito/cognitoUtils";
import { useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { amplifyConfig } from "src/cognito/cognitoconfig";
import { UserContext } from "@idsk/ui-core-framework";

export const ResetPassword = () => {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const password = Form.useWatch("newPassword", form);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setConfirmShowPassword] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const onForgotPasswordSubmit = (values: UserDetails) => {
    setLoading(true);

    if (searchParams.get("purpose") === "new_password") {
      Auth.signIn(values.username, values.password).then((user) => {
        Auth.completeNewPassword(user, values.newPassword!)
          .catch((e) => {
            console.log(e);
          })
          .then(() => {
            Auth.currentAuthenticatedUser().then((currentUser) => {
              setUser!(currentUser);
              localStorage.setItem(
                "idToken",
                `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
              );
            });
          });
      });
    } else {
      forgotPasswordSubmit(values)
        .then(() => {
          navigate("/login");
        })
        .catch((e) => {
          const err =
            e.message ===
              "PostConfirmation invocation failed due to error Socket timeout while invoking Lambda function." ||
            e.message === "Unrecognizable lambda output";

          if (err) {
            message.success(
              "Password has been resetted.Continue with your login"
            );
            navigate("/login");
          }
          setError(e.message);
          setLoading(false);
        });
    }
  };
  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{ position: "relative", top: "25%" }}
    >
      <Col span={13}>
        <Typography.Text
          style={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#0d3f4b",
            font: "Circular Std",
          }}
        >
          Create New Password?
        </Typography.Text>

        <p>Let set new password to the account.</p>
      </Col>
      {error && (
        <Col span={13}>
          <Alert type="error" message={error} />
        </Col>
      )}

      <Col span={13}>
        <TextInput
          name="newPassword"
          label="New Password"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(!showPassword)} />
            )
          }
          rules={[
            {
              required: true,
              message: "Email Address is required",
            },
            () => ({
              validator(_, password) {
                return validatePassword(password);
              },
            }),
          ]}
        />
      </Col>
      <Col span={13}>
        <TextInput
          name="confirmPassword"
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          rules={[
            {
              validator: (_, value) => {
                if (password === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords should match"));
              },
            },
          ]}
          suffix={
            showConfirmPassword ? (
              <EyeInvisibleOutlined
                onClick={() => setConfirmShowPassword(!showConfirmPassword)}
              />
            ) : (
              <EyeOutlined
                onClick={() => setConfirmShowPassword(!showConfirmPassword)}
              />
            )
          }
          required
        />
      </Col>
      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            background: "#F89621",
            color: "white",
            borderColor: "#E88C20",
          }}
          onClick={() => {
            onForgotPasswordSubmit(form.getFieldValue([]));
          }}
          size="large"
          loading={loading}
        >
          Proceed
        </IdskButton>
      </Col>
    </Row>
  );
};

// export const ResetPassword = () => {
//   const form = Form.useFormInstance();
//   const navigate = useNavigate();
//   const { setUser } = useContext(UserContext);
//   const password = Form.useWatch("newPassword", form);
//   const [showPassword, setShowPassword] = useState<boolean>(false);
//   const [showConfirmPassword, setConfirmShowPassword] =
//     useState<boolean>(false);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string>();
//   const [searchParams, setSearchParams] = useSearchParams();

//   const onForgotPasswordSubmit = (values: UserDetails) => {
//     setLoading(true);

//     if (searchParams.get("purpose") === "new_password") {
//       Auth.signIn(values.username, values.password).then((user) => {
//         Auth.completeNewPassword(user, values.newPassword!)
//           .catch((e) => {
//             console.log(e);
//           })
//           .then(() => {
//             Auth.currentAuthenticatedUser().then((currentUser) => {
//               setUser!(currentUser);
//               localStorage.setItem(
//                 "idToken",
//                 `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
//               );
//             });
//           });
//       });
//     } else {
//       forgotPasswordSubmit(values)
//         .then(() => {
//           navigate("/login");
//         })
//         .catch((e) => {
//           if (
//             e.message ===
//             "PostConfirmation invocation failed due to error Socket timeout while invoking Lambda function."
//           ) {
//             navigate("/login");
//           }
//           setError(e.message);
//           setLoading(false);
//         });
//     }
//   };
//   return (
//     <Row
//       align={"middle"}
//       justify={"center"}
//       gutter={[12, 12]}
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         minHeight: "712px",
//         height: "auto",
//         backgroundImage: `url("/Login.svg")`,
//         backgroundSize: "cover",
//         width: "710px",
//         backgroundRepeat: "no-repeat",
//       }}
//     >
//       <Col span={17}>
//         <div
//           style={{
//             fontWeight: "400",
//             fontSize: "48px",
//             color: "#191919",
//             lineHeight: "56px",
//             marginTop: "150px",
//             textAlign: "center",
//           }}
//         >
//           Create New Password?
//         </div>
//         <div
//           style={{
//             fontWeight: "400",
//             fontSize: "16px",
//             color: "#575757",
//             lineHeight: "20px",
//             textAlign: "center",
//           }}
//         >
//           Let set new password to the account.
//         </div>
//       </Col>
//       {error && (
//         <Col span={13}>
//           <Alert type="error" message={error} />
//         </Col>
//       )}

//       <Col span={13}>
//         <TextInput
//           name="newPassword"
//           label="New Password"
//           type={showPassword ? "text" : "password"}
//           placeholder="Password"
//           suffix={
//             showPassword ? (
//               <EyeInvisibleOutlined
//                 onClick={() => setShowPassword(!showPassword)}
//               />
//             ) : (
//               <EyeOutlined onClick={() => setShowPassword(!showPassword)} />
//             )
//           }
//           rules={[
//             {
//               required: true,
//               message: "Email Address is required",
//             },
//             () => ({
//               validator(_, password) {
//                 return validatePassword(password);
//               },
//             }),
//           ]}
//         />
//       </Col>
//       <Col span={13}>
//         <TextInput
//           name="confirmPassword"
//           label="Confirm Password"
//           type={showPassword ? "text" : "password"}
//           placeholder="Password"
//           rules={[
//             {
//               validator: (_, value) => {
//                 if (password === value) {
//                   return Promise.resolve();
//                 }
//                 return Promise.reject(new Error("Passwords should match"));
//               },
//             },
//           ]}
//           suffix={
//             showConfirmPassword ? (
//               <EyeInvisibleOutlined
//                 onClick={() => setConfirmShowPassword(!showConfirmPassword)}
//               />
//             ) : (
//               <EyeOutlined
//                 onClick={() => setConfirmShowPassword(!showConfirmPassword)}
//               />
//             )
//           }
//           required
//         />
//       </Col>
//       <Col span={13}>
//         <IdskButton
//           style={{
//             width: "100%",
//             color: "white",
//             fontSize: "18px",
//             fontWeight: "700",
//             height: "56px",
//             background: "#0D6EFD",
//             border: "2px solid",
//             borderImageSource:
//               "linear-gradient(180deg, #75ADFF 0%, #1D2FA1 100%)",
//             borderImageSlice: 1,
//           }}
//           onClick={() => {
//             onForgotPasswordSubmit(form.getFieldValue([]));
//           }}
//           size="large"
//           loading={loading}
//         >
//           Proceed
//         </IdskButton>
//       </Col>
//     </Row>
//   );
// };
