import CodeFrameWork from "./CodeFrameWorks/CodeFrameWork";
import CodeIdeFrameWork from "./CodeFrameWorks/CodeIdeFrameWork";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import { AssessmentSessionLayoutProps } from "./CodeFrameWorks/AssessmentSession.df";

const WrappedCodeFrameWork = (props: AssessmentSessionLayoutProps) => {
  return (
    <props.asyncAssessmentSessionUi.Wrapper>
      <SandpackProvider theme="dark">
        <CodeFrameWork {...props} />
      </SandpackProvider>
    </props.asyncAssessmentSessionUi.Wrapper>
  );
};

const CodeIde = () => {
  return (
    <SandpackProvider theme="dark">
      <CodeIdeFrameWork />
    </SandpackProvider>
  );
};

export { WrappedCodeFrameWork as CodeFrameWork, CodeIde };
