// CodeFrameWork.tsx

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useNavigate, useParams } from "react-router";
import {
  SandpackCodeEditor,
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
  SandpackPredefinedTemplate,
  useSandpack,
  SandpackTests,
} from "@codesandbox/sandpack-react";
import { AssessmentUploadAxiosInstance } from "@idsk/ui-core-framework";
import { RcFile } from "antd/lib/upload";
import SandpackFileExplorer from "@rainetian/sandpack-file-explorer";
import {
  getStageType,
  StageType,
  getLoadableAssetsCandidateLinkFromPath,
  getAxiosInstance,
} from "@idsk/ui-core-framework";
import Split from "react-split";
import { Layout, Button, Select, message, Spin } from "antd";
import { FileOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { bufferDecode } from "@idsk/components-props";
import JSZip from "jszip";
import {
  AssessmentSessionLayoutProps,
  patchAssessmentSession,
  updateQuestionInAssessment,
} from "./AssessmentSession.df";
import "./CodeFrameWork.css";

import { EditorView } from "@codemirror/view";

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error in child component:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Error displaying test results.</div>;
    }
    return this.props.children;
  }
}

const { Sider, Content } = Layout;

interface CodeTemplate {
  path: string;
  code: string;
  state: string;
  readOnly: boolean;
}

interface Question {
  id: string;
  description?: string;
  questionTitle?: string;
  codeTemplatesLink?: string;
  framework?: string;
  clientId: string;
  candidateResponse?: string;
  codeTemplates?: FileState[];
  providedAnswer?: string;
  totalTestCases?: number;
  passedTestCases?: number;
  attempted?: boolean;
}

interface FileState {
  path: string;
  code: string;
  readOnly?: boolean;
}

interface TestResult {
  tests?: Record<string, any>;
  describes?: Record<string, TestResult>;
}

const CodeFrameWork: React.FC<AssessmentSessionLayoutProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [assessment, setAssessment] = useState<any>({});
  const [uiState, setUiState] = useState({
    candidateSolutionS3: "",
    isExplorerOpen: false,
    isQuestionOpen: true,
    showTestCases: false,
    codeIdeLoading: false,
    codeTemplates: [] as FileState[],
    template: "react" as SandpackPredefinedTemplate,
    selectedQuestion: {} as Question,
    previousFileStates: [] as FileState[],
    score: null as { total: number; passed: number; failed: number } | null,
    questionsOptions: [] as Question[],
  });

  const environment =
    getStageType() === StageType.DEVELOPMENT ? "dev" : getStageType();

  const filesRef = useRef<Record<string, CodeTemplate>>({});
  const testResultsRef = useRef<TestResult | null>(null);

  const normalizeFilePath = (filename: string) => {
    const normalizedFilename = filename.replace(/\\/g, "/");
    return normalizedFilename.startsWith("/")
      ? normalizedFilename
      : "/" + normalizedFilename;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setUiState((prevState) => ({
          ...prevState,
          codeIdeLoading: true,
        }));
        const assessmentResponse =
          props?.asyncAssessmentSessionUi?.getResponse();
        let sections;
        const environmentFinal =
          getStageType() === "development" ? "dev" : getStageType();

        if (assessmentResponse?.status === "IN_PROGRESS") {
          setAssessment(assessmentResponse);
          sections = assessmentResponse?.sections;
        } else {
          setAssessment(assessmentResponse);
          sections = assessmentResponse?.sections;
        }

        const questions =
          sections?.flatMap((section: any) => section.questions) || [];

        if (questions.length > 0) {
          const firstQuestion = questions[0];
          const currentQuestion = uiState.selectedQuestion?.id
            ? uiState.selectedQuestion
            : firstQuestion;

          setUiState((prevState) => ({
            ...prevState,
            questionsOptions: questions,
            selectedQuestion: currentQuestion,
            template:
              mapLanguageToTemplate(currentQuestion.framework) || "react",
          }));

          if (
            currentQuestion.providedAnswer ||
            currentQuestion.codeTemplatesLink
          ) {
            await fetchCodeTemplates(currentQuestion, true);
          }
        }
      } catch (error: any) {
        console.error("Error loading data:", error);
        message.error("Error loading data! Please try again.");
      } finally {
        setUiState((prevState) => ({
          ...prevState,
          codeIdeLoading: false,
        }));
      }
    };
    fetchData();
  }, [props.asyncAssessmentSessionUi.getResponse(), environment, id]);

  const mapLanguageToTemplate = (
    framework?: string
  ): SandpackPredefinedTemplate | undefined => {
    switch (framework?.toLowerCase()) {
      case "react":
        return "react";
      case "vue":
        return "vue";
      case "angular":
        return "angular";
      case "svelte":
        return "svelte";
      case "vanilla":
        return "vanilla";
      default:
        return "react";
    }
  };

  const handleSaveTemplate = useCallback(
    async (
      fileStates: FileState[],
      totalTestCases: number,
      passedTestCases: number
    ): Promise<any> => {
      if (
        JSON.stringify(fileStates) !==
        JSON.stringify(uiState.previousFileStates)
      ) {
        setUiState((prevState) => ({
          ...prevState,
          previousFileStates: fileStates,
        }));
      }

      try {
        const currentQuestionId = uiState.selectedQuestion.id;
        const clientId = uiState.selectedQuestion.clientId;
        if (!currentQuestionId || !clientId) {
          message.error("No question selected or client ID missing.");
          return;
        }

        const zip = new JSZip();
        fileStates.forEach((file) => {
          zip.file(file.path, file.code);
        });

        const zipContent = await zip.generateAsync({ type: "arraybuffer" });
        const zipBlob = new Blob([zipContent], { type: "application/zip" });
        const zipFile = new File([zipBlob], "codeTemplates.zip", {
          type: "application/zip",
        });
        const uploadUrl = `/frontendframework/answers/sessions/${id}/${currentQuestionId}.zip`;
        let rcZipFile: RcFile;
        try {
          rcZipFile = zipFile as RcFile;
        } catch (castingError) {
          console.warn("Casting File to RcFile failed. Using File directly.");
          rcZipFile = zipFile as any;
        }

        const uploadResponse = await AssessmentUploadAxiosInstance({
          url: uploadUrl,
          data: rcZipFile,
          method: "POST",
          headers: {
            "Content-Type": "application/zip",
          },
        });

        if (uploadResponse.status !== 200 && uploadResponse.status !== 201) {
          message.error(
            `Failed to upload code templates to S3. Status: ${uploadResponse.status}`
          );
          return;
        }

        const environmentFinal =
          getStageType() === "development" ? "dev" : getStageType();
        const s3Link = `https://idesk-${environmentFinal}-assessment.s3.amazonaws.com/frontendframework/answers/sessions/${id}/${currentQuestionId}.zip`;

        const updatedAssessment = {
          ...assessment,
          status: "IN_PROGRESS",
          sections: assessment.sections.map((section: any) => ({
            ...section,
            questions: section.questions.map((question: any) =>
              question.id === currentQuestionId
                ? {
                    ...question,
                    providedAnswer: s3Link,
                    attempted: true,
                    totalTestCases,
                    passedTestCases,
                  }
                : question
            ),
          })),
        };

        setAssessment(updatedAssessment);

        await patchAssessmentSession(
          props.bgSubmitHook,
          updatedAssessment,
          null,
          null
        );
        return updatedAssessment;
      } catch (error: any) {
        console.error("Error saving template:", error);
        message.error("Error saving template! Please try again.");
      }
    },
    [
      uiState.previousFileStates,
      uiState.selectedQuestion.id,
      uiState.selectedQuestion.clientId,
      props.bgSubmitHook,
      assessment,
      id,
      uiState.selectedQuestion,
    ]
  );

  const fetchCodeTemplates = async (
    question: Question,
    shouldLoad: boolean
  ): Promise<void> => {
    try {
      setUiState((prevState) => ({
        ...prevState,
        codeIdeLoading: shouldLoad,
      }));
      let codeTemplatesUrl: string;

      const isAttempted =
        question.candidateResponse ||
        (question.attempted && question.providedAnswer);
      if (isAttempted) {
        const codeTemplatesS3Path = `/assets/idesk-${environment}-assessment/frontendframework/answers/sessions/${id}/${question.id}.zip`;
        codeTemplatesUrl =
          getLoadableAssetsCandidateLinkFromPath(codeTemplatesS3Path);
      } else {
        const { id: questionId, clientId } = question;
        const codeTemplatesS3Path = `/assets/idesk-${environment}-assessment/frontendframework/questions/clients/${clientId}/${questionId}/codeTemplates.zip`;
        codeTemplatesUrl =
          getLoadableAssetsCandidateLinkFromPath(codeTemplatesS3Path);
      }

      if (!codeTemplatesUrl) {
        message.error("No valid link found for fetching code templates.");
        return;
      }
      const axiosInstance = getAxiosInstance();
      let s3Response;

      if (isAttempted) {
        s3Response = await axiosInstance.get(codeTemplatesUrl);
      } else {
        s3Response = await axiosInstance.get(codeTemplatesUrl, {
          headers: { useMirage: false },
        });
      }

      if (!s3Response.data) {
        message.error("Code template URL is unavailable!");
        setUiState((prevState) => ({
          ...prevState,
          codeIdeLoading: false,
        }));
        return;
      }

      const response = await fetch(s3Response.data, { method: "GET" });
      if (!response.ok) {
        const errorText = await response.text();
        console.error(
          `Failed to fetch code templates. Status: ${response.status}`
        );
        console.error("Error Response:", errorText);
        message.error(
          `Failed to fetch code templates. Status: ${response.status}`
        );
        return;
      }

      const zipBlob = await response.blob();
      const zip = await JSZip.loadAsync(zipBlob);
      const fileStates: FileState[] = [];

      await Promise.all(
        Object.keys(zip.files).map(async (filename) => {
          if (!filename) {
            console.warn("Encountered undefined filename in zip files.");
            return;
          }
          const file = zip.files[filename];
          if (!file.dir) {
            try {
              const content = await file.async("string");
              const filePath = normalizeFilePath(filename);
              if (!filePath) {
                console.warn("Undefined filePath for filename:", filename);
                return;
              }
              fileStates.push({
                path: filePath,
                code: content,
                readOnly: false,
              });
            } catch (fileError) {
              console.error(`Error reading file ${filename}:`, fileError);
            }
          }
        })
      );

      setUiState((prevState) => ({
        ...prevState,
        codeTemplates: fileStates,
      }));
    } catch (error) {
      console.error("Error fetching code templates:", error);
      message.error("Failed to fetch code templates.");
    } finally {
      setUiState((prevState) => ({
        ...prevState,
        codeIdeLoading: false,
      }));
    }
  };

  const handleQuestionChange = useCallback(
    async (
      fileStates: FileState[],
      totalTestCases: number,
      passedTestCases: number,
      value: any
    ) => {
      let selectedQuestion = assessment?.sections[0]?.questions?.find(
        (q: any) => q.id === value
      );
      if (!selectedQuestion) {
        message.error("Selected question not found.");
        return;
      }

      try {
        const currentQuestionId = uiState.selectedQuestion.id;
        if (currentQuestionId) {
          const hasUnsavedChanges =
            JSON.stringify(fileStates) !==
            JSON.stringify(uiState.previousFileStates);
          if (hasUnsavedChanges) {
            await handleSaveTemplate(
              fileStates,
              totalTestCases,
              passedTestCases
            );
          }
        }

        setUiState((prevState) => ({
          ...prevState,
          selectedQuestion: selectedQuestion,
          template:
            mapLanguageToTemplate(selectedQuestion.framework) || "react",
          codeTemplates: [],
          previousFileStates: [],
          score: null,
        }));

        if (
          selectedQuestion.providedAnswer ||
          selectedQuestion.codeTemplatesLink
        ) {
          await fetchCodeTemplates(selectedQuestion, true);
        }
      } catch (error) {
        console.error("Failed to save before changing question:", error);
        message.error("Failed to save current code. Please try again.");
      } finally {
        setUiState((prevState) => ({
          ...prevState,
          codeIdeLoading: false,
        }));
      }
    },
    [
      handleSaveTemplate,
      fetchCodeTemplates,
      setAssessment,
      id,
      assessment,
      uiState.selectedQuestion.id,
      uiState.previousFileStates,
    ]
  );

  const memoizedFiles = useMemo(() => {
    try {
      const codeTemplates: Record<string, CodeTemplate> =
        uiState.codeTemplates.reduce(
          (acc: Record<string, CodeTemplate>, fileState: FileState) => {
            if (fileState.path && fileState.code !== undefined) {
              acc[fileState.path] = {
                path: fileState.path,
                code: fileState.code,
                state: "existing",
                readOnly: fileState.readOnly ?? false,
              };
            } else {
              console.warn("Invalid fileState detected:", fileState);
            }
            return acc;
          },
          {}
        );

      return codeTemplates;
    } catch (error) {
      console.error("Error processing code templates:", error);
      return {};
    }
  }, [uiState.codeTemplates]);

  const handleTestResults = useCallback(
    (results: Record<string, TestResult>) => {
      testResultsRef.current = results;

      let totalTests = 0;
      let passedTests = 0;
      let failedTests = 0;

      const traverseResults = (node: TestResult) => {
        if (!node) return;

        if (
          node.tests &&
          typeof node.tests === "object" &&
          node.tests !== null
        ) {
          Object.values(node.tests || {}).forEach((test: any) => {
            totalTests++;
            test.status === "pass" ? passedTests++ : failedTests++;
          });
        }

        if (
          node.describes &&
          typeof node.describes === "object" &&
          node.describes !== null
        ) {
          Object.values(node.describes || {}).forEach(traverseResults);
        }
      };

      if (results && typeof results === "object" && results !== null) {
        Object.values(results).forEach(traverseResults);
      }

      const score = {
        total: totalTests,
        passed: passedTests,
        failed: failedTests,
      };

      setTimeout(() => {
        setUiState((prevState) => ({
          ...prevState,
          score,
        }));
      }, 0);
    },
    []
  );

  const SandpackQuestionLogger: React.FC<{
    onQuestionChange: (
      fileStates: FileState[],
      totalTestCases: number,
      passedTestCases: number,
      value: any
    ) => void;
  }> = ({ onQuestionChange }) => {
    const { sandpack } = useSandpack();
    const { files, activeFile } = sandpack;

    const handleQuestionChangeInternal = (value: string) => {
      const fileStates: FileState[] = Object.entries(files)
        .filter(
          ([filePath, fileData]) =>
            filePath && fileData && fileData.code !== undefined
        )
        .map(([filePath, fileData]) => ({
          path: filePath,
          code: fileData.code,
          readOnly: filesRef.current[filePath]?.readOnly ?? false,
        }));

      const totalTestCases = uiState.score?.total || 0;
      const passedTestCases = uiState.score?.passed || 0;
      onQuestionChange(fileStates, totalTestCases, passedTestCases, value);

      if (activeFile && activeFile.includes(".test.")) {
        setUiState((prevState) => ({ ...prevState, showTestCases: true }));
      }
    };

    return (
      <div className="question-logger-container">
        <div className="label-select-container">
          <label htmlFor="question-select" className="question-label">
            Question
          </label>
          <Select
            id="question-select"
            style={{ width: 500 }}
            placeholder="Select a Question"
            value={uiState.selectedQuestion?.id}
            onChange={handleQuestionChangeInternal}
            options={uiState.questionsOptions.map((question) => ({
              label: question?.questionTitle,
              value: question?.id,
            }))}
            aria-label="Select a Question"
          />
        </div>
      </div>
    );
  };

  const SandpackLogger: React.FC<{
    onSave: (
      fileStates: FileState[],
      totalTestCases: number,
      passedTestCases: number
    ) => void;
  }> = ({ onSave }) => {
    const { sandpack } = useSandpack();
    const { files, activeFile } = sandpack;

    const handleSubmit = () => {
      const fileStates: FileState[] = Object.entries(files)
        .filter(
          ([filePath, fileData]) =>
            filePath && fileData && fileData.code !== undefined
        )
        .map(([filePath, fileData]) => ({
          path: filePath,
          code: fileData.code,
          readOnly: filesRef.current[filePath]?.readOnly ?? false,
        }));

      const totalTestCases = uiState.score?.total || 0;
      const passedTestCases = uiState.score?.passed || 0;

      onSave(fileStates, totalTestCases, passedTestCases);

      if (activeFile && activeFile.includes(".test.")) {
        setUiState((prevState) => ({ ...prevState, showTestCases: true }));
      }
    };

    const handleCompleteAssessment = async () => {
      const fileStates: FileState[] = Object.entries(files)
        .filter(
          ([filePath, fileData]) =>
            filePath && fileData && fileData.code !== undefined
        )
        .map(([filePath, fileData]) => ({
          path: filePath,
          code: fileData.code,
          readOnly: filesRef.current[filePath]?.readOnly ?? false,
        }));

      const totalTestCases = uiState.score?.total || 0;
      const passedTestCases = uiState.score?.passed || 0;

      try {
        const currentQuestionId = uiState.selectedQuestion.id;
        const clientId = uiState.selectedQuestion.clientId;
        if (!currentQuestionId || !clientId) {
          message.error("No question selected or client ID missing.");
          return;
        }

        const zip = new JSZip();
        fileStates.forEach((file) => {
          zip.file(file.path, file.code);
        });

        const zipContent = await zip.generateAsync({ type: "arraybuffer" });
        const zipBlob = new Blob([zipContent], { type: "application/zip" });
        const zipFile = new File([zipBlob], "codeTemplates.zip", {
          type: "application/zip",
        });
        const uploadUrl = `/frontendframework/answers/sessions/${id}/${currentQuestionId}.zip`;
        let rcZipFile: RcFile;
        try {
          rcZipFile = zipFile as RcFile;
        } catch (castingError) {
          console.warn("Casting File to RcFile failed. Using File directly.");
          rcZipFile = zipFile as any;
        }

        const uploadResponse = await AssessmentUploadAxiosInstance({
          url: uploadUrl,
          data: rcZipFile,
          method: "POST",
          headers: {
            "Content-Type": "application/zip",
          },
        });

        if (uploadResponse.status !== 200 && uploadResponse.status !== 201) {
          message.error(
            `Failed to upload code templates to S3. Status: ${uploadResponse.status}`
          );
          return;
        }

        const environmentFinal =
          getStageType() === "development" ? "dev" : getStageType();
        const s3Link = `https://idesk-${environmentFinal}-assessment.s3.amazonaws.com/frontendframework/answers/sessions/${id}/${currentQuestionId}.zip`;

        const updatedAssessment = {
          ...assessment,
          status: "COMPLETED",
          sections: assessment.sections.map((section: any) => ({
            ...section,
            questions: section.questions.map((question: any) =>
              question.id === currentQuestionId
                ? {
                    ...question,
                    providedAnswer: s3Link,
                    attempted: true,
                    totalTestCases,
                    passedTestCases,
                  }
                : question
            ),
          })),
        };

        setAssessment(updatedAssessment);

        await patchAssessmentSession(
          props.bgSubmitHook,
          updatedAssessment,
          null,
          null
        );
        message.success(
          "Code templates uploaded and assessment session updated successfully."
        );
        navigate("/dashboard");
      } catch (error: any) {
        console.error("Error saving template:", error);
        message.error("Error saving template! Please try again.");
      }
    };

    return (
      <div className="test-logger-container">
        <div className="left-buttons">
          <Button
            onClick={handleSubmit}
            type="primary"
            className="logger-button"
            style={{ backgroundColor: "#3F51B5" }}
          >
            Submit Code
          </Button>
          <Button
            onClick={() =>
              setUiState((prevState) => ({
                ...prevState,
                showTestCases: !prevState.showTestCases,
              }))
            }
            type="primary"
            className="logger-button"
          >
            {uiState.showTestCases ? "Hide Test Cases" : "Show Test Cases"}
          </Button>
        </div>
        <Button
          onClick={handleCompleteAssessment}
          type="primary"
          className="complete-assessment-button"
          style={{ backgroundColor: "#4caf50" }}
        >
          Complete Assessment
        </Button>
      </div>
    );
  };

  const keyListenerExtension = EditorView.updateListener.of((update) => {
    if (update.docChanged) {
      setUiState((prevState) => ({ ...prevState, showTestCases: false }));
    }
  });

  return uiState.codeIdeLoading && uiState.selectedQuestion ? (
    <div className="loadingContainer">
      <Spin size="large" tip="Loading..." />
    </div>
  ) : (
    <props.asyncAssessmentSessionUi.Wrapper>
      <SandpackProvider
        key={uiState.selectedQuestion.id}
        template={uiState.template}
        files={memoizedFiles}
        theme="dark"
      >
        <SandpackQuestionLogger onQuestionChange={handleQuestionChange} />
        <Layout
          className={`${
            !uiState.showTestCases ? "content-layout-height" : ""
          } content-layout`}
        >
          <div className="code-sidebar">
            {uiState.selectedQuestion && (
              <Button
                type="primary"
                icon={<QuestionCircleOutlined />}
                onClick={() =>
                  setUiState((prevState) => ({
                    ...prevState,
                    isQuestionOpen: !prevState.isQuestionOpen,
                    isExplorerOpen: false,
                  }))
                }
                className="icon-button"
                aria-label="Toggle Question Sidebar"
              />
            )}
            <Button
              type="primary"
              icon={<FileOutlined />}
              onClick={() =>
                setUiState((prevState) => ({
                  ...prevState,
                  isExplorerOpen: !prevState.isExplorerOpen,
                  isQuestionOpen: false,
                }))
              }
              className="icon-button"
              aria-label="Toggle File Explorer"
            />
          </div>
          <Sider
            collapsible
            collapsed={!uiState.isQuestionOpen}
            width={400}
            collapsedWidth={0}
            trigger={null}
            className="question-explorer"
          >
            <div
              className="question-content"
              style={{
                height: uiState.showTestCases
                  ? "calc(100vh - 14.5rem)"
                  : "84.7vh",
              }}
            >
              <h2>{uiState.selectedQuestion?.questionTitle}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: bufferDecode(
                    uiState.selectedQuestion?.description || ""
                  ),
                }}
              />
            </div>
          </Sider>
          <Sider
            collapsible
            collapsed={!uiState.isExplorerOpen}
            width={300}
            collapsedWidth={0}
            trigger={null}
            className="file-explorer"
          >
            <div className="file-explorer-content">
              <SandpackFileExplorer
                style={{
                  height: uiState.showTestCases
                    ? "calc(100vh - 22.3rem)"
                    : "calc(100vh - 9.75rem)",
                  fontSize: "14px",
                }}
              />
            </div>
          </Sider>
          <Content
            className={`${uiState.isExplorerOpen ? "content" : "shifted"}`}
          >
            <SandpackLayout
              style={{
                height: uiState.showTestCases
                  ? "calc(100vh - 26rem)"
                  : "calc(100vh - 12.3rem)",
              }}
            >
              <Split
                className="split"
                sizes={[50, 50]}
                minSize={200}
                gutterSize={3}
              >
                <div className="editor-pane">
                  <SandpackCodeEditor
                    style={{ height: "100%" }}
                    showLineNumbers
                    showTabs
                    closableTabs
                    showInlineErrors
                    wrapContent
                    initMode="lazy"
                    extensions={[keyListenerExtension]}
                  />
                </div>
                <div className="preview-pane">
                  <SandpackPreview
                    showOpenInCodeSandbox={false}
                    style={{ height: "100%" }}
                  />
                </div>
              </Split>
            </SandpackLayout>
            <SandpackLogger onSave={handleSaveTemplate} />
          </Content>
        </Layout>
        {uiState.showTestCases && (
          <div
            className={`testRunner ${
              uiState.showTestCases ? "show-testcase" : "hide-testcase"
            }`}
          >
            <ErrorBoundary>
              <SandpackTests verbose onComplete={handleTestResults} />
            </ErrorBoundary>
          </div>
        )}
      </SandpackProvider>
    </props.asyncAssessmentSessionUi.Wrapper>
  );
};

export default CodeFrameWork;
