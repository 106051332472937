import { useState, useEffect, useCallback, useRef } from "react";
import {
  SandpackCodeEditor,
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
  SandpackPredefinedTemplate,
  useSandpack,
} from "@codesandbox/sandpack-react";
import SandpackFileExplorer from "@rainetian/sandpack-file-explorer";
import Split from "react-split";
import { Layout, Button, Modal, Card, Row, Col } from "antd";
import { FileOutlined, CodeOutlined } from "@ant-design/icons";
import { sandpackTemplates } from "../../utils/templates";
import "./CodeFrameWork.css";
import React from "react";

const { Sider, Content } = Layout;

interface CodeTemplate {
  path: string;
  code: string;
  state: string;
  readOnly: boolean;
}

interface Question {
  description?: string;
  title?: string;
  codeTemplates?: CodeTemplate[];
  language?: SandpackPredefinedTemplate;
}

const CodeIdeFrameWork: React.FC = () => {
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [isExplorerOpen, setIsExplorerOpen] = useState<boolean>(false);
  const [question, setQuestion] = useState<Question>({});
  const [template, setTemplate] = useState<SandpackPredefinedTemplate>("react");
  const [initialCode, setInitialCode] = useState<Record<
    string,
    CodeTemplate
  > | null>(null);
  const [showTestCases, setShowTestCases] = useState<boolean>(true);
  const { sandpack } = useSandpack();
  const { updateFile, activeFile } = sandpack;
  const filesRef = useRef<Record<string, string>>({});

  const loadQuestionData = useCallback(async () => {
    setTemplate("react");
    setInitialCode({});
    setIsTemplateModalVisible(true);
  }, []);

  useEffect(() => {
    loadQuestionData();
  }, [loadQuestionData]);

  useEffect(() => {
    if (initialCode && Object.keys(initialCode).length) {
      Object.entries(initialCode).forEach(([filePath, fileContent]) => {
        if (filesRef.current[filePath] !== fileContent.code) {
          updateFile(filePath, fileContent.code);
          filesRef.current[filePath] = fileContent.code;
        }
      });
    }
  }, [initialCode, updateFile]);

  useEffect(() => {
    if (activeFile.includes(".test.")) {
      setShowTestCases(true);
    }
  }, [activeFile]);

  const handleTemplateSelect = (
    selectedTemplate: SandpackPredefinedTemplate
  ) => {
    setTemplate(selectedTemplate);
    setIsTemplateModalVisible(false);
    setInitialCode({});
  };

  const toggleSidebar = () => {
    setIsExplorerOpen(!isExplorerOpen);
  };

  const toggleTemplateModal = () => {
    setIsTemplateModalVisible(!isTemplateModalVisible);
  };

  if (!initialCode) {
    return <div>Loading...</div>;
  }

  return (
    <SandpackProvider
      key={template}
      template={template}
      files={initialCode}
      theme="dark"
    >
      <Layout
        className={`${
          !showTestCases && "content-layout-height"
        } content-layout`}
      >
        <div className="code-sidebar">
          <Button
            type="primary"
            icon={<CodeOutlined />}
            onClick={toggleTemplateModal}
            className="icon-button"
          />
          <Button
            type="primary"
            icon={<FileOutlined />}
            onClick={toggleSidebar}
            className="icon-button"
          />
        </div>

        <Modal
          title="Choose a Template"
          open={isTemplateModalVisible}
          onCancel={toggleTemplateModal}
          footer={null}
          width={600}
        >
          <Row gutter={[16, 16]}>
            {sandpackTemplates.map((template) => (
              <Col span={8} key={template.templateKey}>
                <Card
                  hoverable
                  onClick={() =>
                    handleTemplateSelect(
                      template.templateKey as SandpackPredefinedTemplate
                    )
                  }
                  style={{ textAlign: "center" }}
                >
                  <div style={{ marginBottom: 16 }}>
                    <div>{template.logo}</div>
                  </div>
                  <Card.Meta title={template.name} />
                </Card>
              </Col>
            ))}
          </Row>
        </Modal>

        <Sider
          collapsible
          collapsed={!isExplorerOpen}
          width={300}
          trigger={null}
          className="file-explorer"
        >
          <div className="file-explorer-content">
            <SandpackFileExplorer
              style={{
                height: showTestCases
                  ? "calc(100vh - 7rem)"
                  : "calc(100vh - 9.75rem)",
                fontSize: "14px",
              }}
            />
          </div>
        </Sider>

        <Content className={`content ${isExplorerOpen ? "" : "shifted"}`}>
          <SandpackLayout
            style={{
              height: showTestCases
                ? "calc(100vh - 7rem)"
                : "calc(100vh - 12.8rem)",
            }}
          >
            <Split
              className="split"
              sizes={[50, 50]}
              minSize={200}
              gutterSize={3}
            >
              <div className="editor-pane">
                <SandpackCodeEditor
                  style={{ height: "100%" }}
                  showLineNumbers
                  showTabs
                  closableTabs
                  showInlineErrors
                  wrapContent
                />
              </div>
              <div className="preview-pane">
                <SandpackPreview style={{ height: "100%" }} />
              </div>
            </Split>
          </SandpackLayout>
        </Content>
      </Layout>
    </SandpackProvider>
  );
};

export default CodeIdeFrameWork;
